<template lang="pug">
.page-wrapper
    Menu
        template(#title)
            .flex.items-center
                | Price Analysis

    .container
        template(v-if="isMounted")
            .filters
                AppSelect(
                    name="Категория"
                    has-search
                    search-placeholder="Поиск категории"
                    :items="currentCategories"
                    :selected-item="selectedCategory"
                    @select="selectCategory"
                )
                MultiSelectWithSearch(
                    name="Селлеры"
                    :items="sellers"
                    searchPlaceholder="Поиск селлеров"
                    hasSelectAll
                    @select-item="selectSeller"
                    @select-all="selectAllSellers(true)"
                    @clear-all="selectAllSellers(false)"
                )
                MultiSelectWithSearch(
                    name="Конкуренты"
                    :items="competitors"
                    searchPlaceholder="Поиск конкурентов"
                    hasSelectAll
                    @select-item="selectCompetitor"
                    @select-all="selectAllCompetitors(true)"
                    @clear-all="selectAllCompetitors(false)"
                )

            .empty-page(v-if="isLoading") 
                Skeleton
            .empty-page(v-else-if="!selectedCategory")
                | Выберите категорию
            .empty-page(v-else-if="!selectedCompetitorsIds.length")
                | Выберите хотя бы одного конкурента

            template(v-else)
                section.section.charts-section
                    ChartsGroup(
                        :request-params="sectionRequestParams"
                        :need-update="needUpdateSections"
                    )

                section.section.table-section
                    PriceAnalysisTableSection(
                        :request-params="sectionRequestParams"
                        :need-update="needUpdateSections"
                    )
        
        .empty-page(v-else) Пожалуйста, выберите значения фильтра
</template>

<script>
import Menu from '@/components/Menu/Menu.vue';
import Skeleton from '@/components/Nestle/Skeleton/Common.vue'
import AppSelect from '@/components/Elements/AppSelect.vue';
import MultiSelectWithSearch from '@/components/Elements/MultiSelect/MultiSelectWithSearch.vue';
import ChartsGroup from '@/components/PriceAnalysis/ChartsGroup.vue';
import PriceAnalysisTableSection from '@/components/PriceAnalysis/PriceAnalysisTableSection.vue';

export default {
    components: {
        Menu,
        Skeleton,
        AppSelect,
        MultiSelectWithSearch,
        ChartsGroup,
        PriceAnalysisTableSection,
    },

    props: {
		needUpdateResult: {
			type: Boolean,
			default: false,
		},
		stores: {
			type: String,
			default: '',
		},
		categories: {
			type: String,
			default: '',
		},
		brands: {
			type: String,
			default: '',
		},
		regions: {
			type: String,
			default: '',
		},
		dateFrom: {
			type: String,
			default: '',
		},
		dateTo: {
			type: String,
			default: '',
		},

	},

    data() {
        return {
            isMounted: false,
            isLoading: false,

            pageCategories: [],
            selectedCategory: null,
            sellers: [],
            competitors: [],

            needUpdateSections: false,
        }
    },

    computed: {
        selectedSellersIds() {
            const sellectedSellers = this.sellers.filter((seller) => seller.checked)
            return sellectedSellers.map((seller) => seller.id);
        },

        selectedCompetitorsIds() {
            const selectedCompetitors = this.competitors.filter((competitor) => competitor.checked)
            return selectedCompetitors.map((competitor) => competitor.id);
        },

        currentCategories() {
            const filterCategories = this.categories.split(',');
            return this.pageCategories.filter((category) => filterCategories.includes(category.id));
        },

        sectionRequestParams() {
            const category = this.selectedCategory?.id || this.categories.split(',')[0];
            return {
                stores: this.stores.split(','),
                categories: [category],
                brands: this.brands.split(','),
                regions: this.regions.split(','),
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
                competitorBrands: this.selectedCompetitorsIds,
                sellers: this.selectedSellersIds,
            }
        }
    },

    methods: {
        async fetchCategories() {
            try {
                const response = await this.$api.nestle.getCategories({
                    stores: this.stores
                });
                const isResponseValid = response && Array.isArray(response);

                this.pageCategories = isResponseValid ? response : [];
            } catch {
                this.pageCategories = [];
                this.selectedCategory = null;
            }
        },

        async fetchSellers() {
            if (!this.selectedCategory) return;

            const params = {
                stores: this.stores,
                categories: this.selectedCategory.id,
                brands: this.brands,
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
                competitorBrands: this.selectedCompetitorsIds.join(','),
            }

            try {
                const response = await this.$api.unilever.getSellersList(params);

                if (response && Array.isArray(response.sellers)) {
                    this.sellers = response.sellers.map((seller) => {
                        return {
                            ...seller,
                            checked: false
                        }
                    })
                } else {
                    this.sellers = []
                }
            } catch {
                this.sellers = [];
            }
        },

        async fetchCompetitorsBrands() {
            try {
                const response = await this.$api.unilever.getCompetitorsBrands();

                if (response && Array.isArray(response.brands)) {
                    this.competitors = response.brands.map((brand) => {
                        return {
                            id: brand.id,
                            name: brand.name,
                            checked: true,
                        }
                    })
                } else {
                    this.competitors = []
                }
            } catch {
                this.competitors = [];
            }
        },

        async selectCategory(category) {
            this.selectedCategory = category;

            this.isLoading = true;
            await this.fetchSellers();
            this.isLoading = false;
        },

        selectSeller(itemId) {
            const currentItem = this.sellers.find((item) => item.id === itemId);
            if (!currentItem) return;

            currentItem.checked = !currentItem.checked;
            this.needUpdateSections = !this.needUpdateSections;
        },

        selectAllSellers(checkedValue) {
            this.sellers.forEach((item) => item.checked = checkedValue);
            this.needUpdateSections = !this.needUpdateSections;
        },

        async selectCompetitor(itemId) {
            const currentItem = this.competitors.find((item) => item.id === itemId);
            if (!currentItem) return;

            currentItem.checked = !currentItem.checked;

            if (!this.selectedCompetitorsIds.length) return;

            this.isLoading = true;
            await this.fetchSellers();
            this.isLoading = false;
        },

        async selectAllCompetitors(checkedValue) {
            this.competitors.forEach((item) => item.checked = checkedValue);
            if (!checkedValue) return;

            this.isLoading = true;
            await this.fetchSellers();
            this.isLoading = false;
        },
    },

    watch: {
        async needUpdateResult() {
            this.isMounted = true;
            this.isLoading = true;

            await this.fetchCategories();
            await this.fetchCompetitorsBrands();
            await this.fetchSellers();

            this.isLoading = false;
        },
    },
}
</script>

<style  lang="scss">
.container {
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.filters {
    display: flex;
    align-items: center;
    gap: 12px;
}

.empty-page {
	font-size: 16px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
</style>

<template lang="pug">
ContainerForData(:isLoading="isLoading" width="100%")
    template(#header-left)
        .card-title {{ title }}
    template(#header-right)
        .legends
            span.legend(
                v-for="dataset in chartData.datasets"
                :key="dataset.label"
                :class="{'competitors': dataset.label === 'Конкуренты'}"
            )
                | {{ dataset.label }}

    template(#data)
        .empty(v-if="!data || !chartDatasets.length") No Data
        .chart-container(v-else)
            BarChart(:options="chartOptions" :chartData="chartData")
</template>

<script>
import ContainerForData from '@/components/Nestle/ContainerForData.vue';
import BarChart from '@/components/Chart/BarChart.vue';

export default {
    components: {
        ContainerForData,
        BarChart,
    },

    props: {
        title: {
            type: String,
            default: '',
        },
        data: {
            type: Array,
            default: null
        },
        isLoading: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            colors: ['#67AC5B', '#D9D9D9'],

            chartOptions: {
                locale: 'de-DE',
                interaction: {
                    mode: 'index'
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                const label = context.dataset.label || '';
                                const data = context.dataset.data[context.dataIndex];
                                const value = `${data[0]} ₽ - ${data[1]} ₽`;
                            
                                return ' ' + label + ': ' + value;
                            },
                        }
                    }
                }
            },
        }
    },

    computed: {
        chartLabels() {
            if (!this.data) return [];
            return this.data.map((item) => item.date);
        },

        chartDatasets() {
            if (!this.data) return [];

            return [
                {
                    label: 'Наши бренды',
                    data: this.data.map((item) => [item.value.min, item.value.max]),
                    backgroundColor: this.colors[0],
                },
                {
                    label: 'Конкуренты',
                    data: this.data.map((item) => [item.competitorValue.min, item.competitorValue.max]),
                    backgroundColor: this.colors[1],
                },
            ]
        },

        chartData() {
            return {
                labels: this.chartLabels,
                datasets: this.chartDatasets,
            }
        }
    }
}
</script>

<style scoped lang="scss">
.card-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: color(gray-700);
}

.legends {
    display: flex;
    align-items: center;
    gap: 12px;
}

.legend {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #828E9D;

    &::before {
        content: '';
        margin-right: 8px;
        width: 12px;
        height: 12px;
        background-color: #67AC5B;
    }

    &.competitors::before {
        background-color: #A39D9D;
    }
}

.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
}

.chart-container {
    width: 100%;
    height: 100%;
}
</style>
    
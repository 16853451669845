var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ContainerForData',{attrs:{"width":"100%","isLoading":_vm.isLoading},scopedSlots:_vm._u([{key:"header-left",fn:function(){return [_c('span',{staticClass:"table-title"},[_vm._v("SKU")])]},proxy:true},{key:"header-right",fn:function(){return [_c('div',{staticClass:"actions"},[_c('div',{staticClass:"dates"},[_c('button',{staticClass:"date-btn prev",class:{ disabled: _vm.isItFirstDatePage },attrs:{"disabled":_vm.isItFirstDatePage},on:{"click":_vm.getEarlierDates}},[_c('ChevronDownIcon',{staticClass:"icon",attrs:{"size":16}}),_vm._v("Earler dates")],1),_c('button',{staticClass:"date-btn next",class:{ disabled: _vm.isItLastDatePage },attrs:{"disabled":_vm.isItLastDatePage},on:{"click":_vm.getLaterDates}},[_vm._v("Later dates"),_c('ChevronDownIcon',{staticClass:"icon",attrs:{"size":16}})],1)]),_c('Search',{model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],1)]},proxy:true},{key:"data",fn:function(){return [(_vm.tableProducts && _vm.tableProducts.length)?_c('Table',{attrs:{"columns":_vm.tableColumns,"data":_vm.tableProducts},scopedSlots:_vm._u([{key:"imageUrl",fn:function(ref){
var item = ref.item;
var i = ref.i;
var value = ref.value;
return [_c('div',{staticClass:"table-image",style:({backgroundImage: ("url(" + value + ")")})})]}},{key:"description",fn:function(ref){
var item = ref.item;
var i = ref.i;
var value = ref.value;
return [_c('div',{staticClass:"name"},[_vm._v(_vm._s(value.name))]),_c('div',[_c('strong',[_vm._v("RPC")]),_c('span',{staticClass:"ml-8"},[_vm._v(_vm._s(value.rpc))])]),_c('div',[_c('strong',[_vm._v("UPC")]),_c('span',{staticClass:"ml-8"},[_vm._v(_vm._s(value.upc))])])]}},{key:"date",fn:function(ref){
var item = ref.item;
var i = ref.i;
var value = ref.value;
return [_c('div',{staticClass:"flex items-center price-cell"},[_c('Tooltip',{attrs:{"type":"dark","flat":""},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('div',{staticClass:"price"},[_vm._v(_vm._s(value.actualPrice)+" ₽")])]},proxy:true}],null,true)},[_c('div',{staticClass:"tooltip-content"},[_vm._v("Actual Price")])]),_c('Tooltip',{attrs:{"type":"dark","flat":""},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('div',{staticClass:"price regular"},[_vm._v(_vm._s(value.regularPrice)+" ₽")])]},proxy:true}],null,true)},[_c('div',{staticClass:"tooltip-content"},[_vm._v("Regular Price")])])],1)]}}],null,false,955893934)}):[_c('pre',[_vm._v("Data not found")])]]},proxy:true},{key:"pagination",fn:function(){return [(_vm.totalCount > _vm.tableLimit)?_c('div',{staticClass:"element-container__content flex items-center justify-center"},[_c('pagination',{attrs:{"total":_vm.totalCount,"current":_vm.tablePage,"limit":_vm.tableLimit},on:{"change-page":function($event){return _vm.fetchAnotherPage($event)}}})],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }
<template lang="pug">
ContainerForData(width="100%" :isLoading="isLoading")
    template(#header-left)
        span.table-title SKU

    template(#header-right)
        .actions
            .dates
                button.date-btn.prev(
                    :disabled="isItFirstDatePage"
                    :class="{ disabled: isItFirstDatePage }"
                    @click="getEarlierDates"
                )
                    ChevronDownIcon.icon(:size="16")
                    | Earler dates
                button.date-btn.next(
                    :disabled="isItLastDatePage"
                    :class="{ disabled: isItLastDatePage }"
                    @click="getLaterDates"
                )
                    | Later dates
                    ChevronDownIcon.icon(:size="16")

            Search(v-model="searchValue")
    template(#data)
        Table(
            v-if="tableProducts && tableProducts.length"
            :columns="tableColumns"
            :data="tableProducts"
        )
            template(#imageUrl="{item, i, value}")
                .table-image(:style="{backgroundImage: `url(${value})`}")

            template(#description="{item, i, value}")
                .name {{ value.name }}
                div
                    strong RPC
                    span.ml-8 {{ value.rpc }}
                div
                    strong UPC
                    span.ml-8 {{ value.upc }}

            template(#date="{item, i, value}")
                .flex.items-center.price-cell
                    Tooltip(type="dark" flat)
                        template(#reference)
                            .price {{ value.actualPrice }} ₽
                        .tooltip-content Actual Price
                    Tooltip(type="dark" flat)
                        template(#reference)
                            .price.regular {{ value.regularPrice }} ₽
                        .tooltip-content Regular Price
        template(v-else)
            pre Data not found
    template(#pagination)
        .element-container__content.flex.items-center.justify-center(v-if="totalCount > tableLimit")
            pagination(
                :total="totalCount"
                :current="tablePage"
                :limit="tableLimit"
                @change-page="fetchAnotherPage($event)"
            )

</template>

<script>
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue';
import ContainerForData from '@/components/Nestle/ContainerForData.vue';
import Search from "@/components/Nestle/Search"
import Table from "@/components/Table/Table"
import Tooltip from '@/components/Elements/Tooltip.vue';
import Pagination from "@/components/Pagination/Pagination.vue"

export default {
    components: {
        ChevronDownIcon,
        ContainerForData,
        Search,
        Table,
        Tooltip,
        Pagination,
    },

    props: {
        requestParams: {
            type: Object,
            required: true,
        },
        needUpdate: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return{
            searchValue: '',
            isLoading: false,

            tableProducts: [],
            totalCount: 0,
            tablePage: 1,
            tableOffset: 0,
            tableLimit: 20,
            tableDatesFrom: 0,
            tableDatesTo: 6,
        }
    },

    computed: {
        tableRequestParams() {
            const params = {};
            const paramsEntries = Object.entries(this.requestParams);

            paramsEntries.forEach((param) => {
                let paramValue;

                if (Array.isArray(param[1])) {
                    paramValue = param[1].join(',');
                } else {
                    paramValue = param[1];
                }

                params[param[0]] = paramValue;
            })

            params.limit = this.tableLimit;
            params.offset = this.tableOffset;

            return params;
        },

        tableDates() {
            if (!this.tableProducts.length) return [];
            const dates = this.tableProducts[0].dates.map((item) => item.observedAt);
            const sortedDates = this.sortDates(dates);

            return sortedDates.map((date) => {
                return {
                    title: this.$utils.format(this.$utils.parseISO(date), 'MMM dd'),
                    slot: 'date',
					width: 60,
					value: (item)=>{
						return {
                            actualPrice: item.dates.find((item) => item.observedAt === date)?.actualPrice || 0,
                            regularPrice: item.dates.find((item) => item.observedAt === date)?.regularPrice || 0,
                        }
					}
                }
            })
        },

        tableDateCols() {
			return this.tableDates.slice(this.tableDatesFrom, this.tableDatesTo);
		},

        tableColumns() {
			return [
				{
					title: 'Online Store',
					width: 80,
					value: (item)=>{
						return item.retailerName
					}
				},
				{
					title: 'Image',
					width: 80,
					slot: 'imageUrl',
					value: (item)=>{
						return item.imageUrl
					}
				},
				{
					title: 'Description',
					width: 240,
					slot: 'description',
					value: (item)=>{
						return {
							name: item.skuName,
							rpc: item.rpc,
							upc: item.upc
						}
					}
				},
                ...this.tableDateCols,
			]
		},

        isItFirstDatePage() {
            return this.tableDatesFrom === 0;
        },

        isItLastDatePage() {
            return this.tableDatesTo >= this.tableDates.length;
        },
    },

    methods: {
        sortDates(dateStrings) {
            const dates = dateStrings.map((dateStr) => {
                const parts = dateStr.split('-');

                const year = parseInt(parts[0], 10);
                const month = parseInt(parts[1], 10) - 1;
                const day = parseInt(parts[2], 10);

                return new Date(year, month, day);
            })

            const sortedDates = dates.sort((date1, date2) => date1 - date2);
            return sortedDates.map((date) => date.toLocaleDateString('ru-RU').split('.').reverse().join('-'));
        },

        async fetchTableData() {
            this.isLoading = true;

            try {
                const response = await this.$api.unilever.getPriceAnalysisProducts(this.tableRequestParams);
                const isDataValid = response && Array.isArray(response.products);
                this.tableProducts = isDataValid ? response.products : [];
                this.totalCount = response.count || 0;
            } catch {
                this.tableProducts = [];
                this.totalCount = 0;
            }

            this.initDatesPagination();
            this.isLoading = false;
        },

        initDatesPagination() {
            const datesCount = this.tableDates.length
            if (!datesCount) return;

            const lessThanWeek = datesCount - 7 < 0;
            this.tableDatesTo = datesCount;
            this.tableDatesFrom = lessThanWeek ? 0 : this.tableDatesTo - 7;
        },

        getEarlierDates() {
            if (this.isItFirstDatePage) return;
            this.tableDatesFrom -= 1;
            this.tableDatesTo -= 1;
        },

        getLaterDates() {
            if (this.isItLastDatePage) return;
            this.tableDatesFrom += 1;
            this.tableDatesTo += 1;
        },

        async fetchAnotherPage(pageNumber) {
            this.tablePage = pageNumber;
            this.tableOffset = this.tableLimit * (pageNumber - 1);

            await this.fetchTableData();
        },
    },

    watch: {
        async needUpdate() {
            await this.fetchAnotherPage(1);
        },
    },

    async mounted() {
        await this.fetchTableData();
    },
}
</script>

<style scoped lang="scss">
.table-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: #757575;
}

.actions {
    display: flex;
    align-items: center;
}

.dates {
    margin-right: 12px;
    display: flex;
    align-items: center;
}

.date-btn {
    padding: 1px 12px;
    display: flex;
    align-items: center;
    min-width: 112px;
    background-color: #fff;
    border: 1px solid #DFDFDF;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #303030;
    outline: none;

    &:focus {
        outline: none;
    }

    &.disabled {
        pointer-events: none;
        color: #9E9E9E;
    }

    &.prev {
        padding-left: 8px;
        padding-right: 8px;
        border-right: none;
        border-bottom-left-radius: 6px;
        border-top-left-radius: 6px;

        .icon {
            margin-right: 2px;
            transform: rotate(90deg);
        }
    }

    &.next {
        padding-left: 12px;
        padding-right: 8px;
        border-bottom-right-radius: 6px;
        border-top-right-radius: 6px;

        .icon {
            margin-left: auto;
            transform: rotate(-90deg);
        }
    }
}

.table-image{
	width: 40px;
	height: 40px;
	background-size: contain;
	filter: inset(1);
	background-repeat: no-repeat;
	background-position: center center;
}

.price-cell {
    flex-direction: column;
}

.price {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: color(black);
    white-space: nowrap;

    &.regular {
        color: #6B6B6B;
    }
}

.tooltip-content {
    padding: 8px 12px;
    margin: -1px;
    display: block;
    min-width: 92px;
    background-color: #fff;
    border: 1px solid #E0E0E0;
    border-radius: 6px;
    box-shadow: 0px 2px 4px 0px #00000024;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    color: #000;
}
</style>

<template lang="pug">
ContainerForData(:isLoading="isLoading" width="100%")
    template(#header-left)
        .card-title {{ title }}
    template(#header-right)
        Tooltip(trigger="clickToToggle" type="dark" :isNeedClosePopup="needCloseTooltip" flat)
            template(slot="reference")
                button.activator
                    | {{ groupByLabel }}
                    ChevronDownIcon.icon(:size="16")

            ul.select-list
                li.select-item(v-for="item in selectItems" :key="item.value")
                    button.value-button(@click="changeSelectValue(item.value)") {{ item.name }}

    template(#data)
        .empty(v-if="!data || !chartDatasets.length") No Data
        .chart-container(v-else)
            LineChart(
                :options="chartOptions"
                :chartData="chartData"
                :plugins="chartPlugins"
            )
</template>

<script>
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue'
import ContainerForData from '@/components/Nestle/ContainerForData.vue';
import Tooltip from '@/components/Elements/Tooltip.vue';
import LineChart from '@/components/Chart/LineChart.vue';

const LINE_COLORS = [
    '#EB3B5A',
    '#FA8231',
    '#F7B731',
    '#20BF6B',
    '#2D98DA',
    '#4B6584',
    '#0FB9B1',
    '#E4592D',
    '#3867D6',
    '#F8608D',
    '#FC795C',
    '#5DEC7D',
    '#A35CFE',
    '#DF46AB',
    '#63CDFB',
    '#5B1592',
    '#57C026',
    '#AF068A',
    '#1D6A99',
    '#B8222B',
    '#C29A33',
    '#147542',
    '#193A87',
    '#CE184E',
]

export default {
    components: {
        ContainerForData,
        Tooltip,
        LineChart,
        ChevronDownIcon,
    },

    props: {
        title: {
            type: String,
            default: '',
        },
        data: {
            type: Array,
            default: null,
        },
        groupBy: {
            type: String,
            default: 'brand',
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        postfix: {
            type: String,
            default: '₽',
        },
    },

    data() {
        return {
            selectItems: [
                {
                    name: 'По брендам',
                    value: 'brand',
                },
                {
                    name: 'По категории',
                    value: 'category',
                }
            ],
            needCloseTooltip: false,

            chartOptions: {
                locale: 'de-DE',
                interaction: {
                    mode: 'index'
                },
                elements: {
                    point: {
                        radius: 5,
                        hoverRadius: 5,
                        pointStyle: 'circle',
                        backgroundColor: '#FFFFFF',
                    }
                },
                plugins: {
                    tooltip: {
                        intersect: true,
                        callbacks: {
                            label: (context) => {
                                const label = context.dataset.label || '';
                                const postfix = this.postfix === '%' ? this.postfix : ' ' + this.postfix;

                                return ' ' + label + ': ' + context.formattedValue + postfix;
                            },
                            labelColor: (context) => {
                                return {
                                    borderColor: '#FFFFFF',
                                    backgroundColor: context.dataset.borderColor,
                                }
                            }
                        }
                    }
                }
            },

            chartPlugins: [
                {
                    id: 'tooltipLine',
                    beforeDraw: (chart) => {
                        const activeElements = chart.getActiveElements();

                        if (activeElements && activeElements.length) {
                            const ctx = chart.ctx;
                            ctx.save();
                            const activePoint = activeElements[0];

                            ctx.beginPath();
                            ctx.setLineDash([4, 4]);
                            ctx.moveTo(activePoint.element.x, chart.chartArea.top);
                            ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
                            ctx.lineWidth = 1;
                            ctx.strokeStyle = '#9E9E9E';
                            ctx.stroke();
                            ctx.restore();
                        }
                    },
                },
            ],
        }
    },

    computed: {
        groupByLabel() {
            const selectValue = this.selectItems.find((item) => item.value === this.groupBy);
            if (!selectValue) return 'По брендам';

            return selectValue.name;
        },

        chartLabels() {
            if (!this.data) return [];
            const dates = this.data.map((item) => item.date);
            const datesSet = new Set(dates);

            return Array.from(datesSet);
        },

        datasetLabels() {
            if (!this.data) return [];
            const names = this.data.map((item) => item.name);
            const namesSet = new Set(names);

            return Array.from(namesSet);
        },

        chartDatasets() {
            if (!this.data) return [];

            return this.datasetLabels.map((label, index) => {
                const attachedData = this.data.filter((item) => item.name === label);
                const colorsCount = LINE_COLORS.length;
                const datasetColor = index >= colorsCount
                    ? LINE_COLORS[index - colorsCount]
                    : LINE_COLORS[index];

                return {
                    label,
                    data: attachedData.map((item) => item.value),
                    borderColor: datasetColor,
                }
            })
        },

        categoryChartDatasets() {
            if (!this.data) return [];

            return [
                {
                    label: 'Мои товары',
                    data: this.data.map((item) => item.value),
                    borderColor: '#67AC5B',
                },
                {
                    label: 'Товары конкурентов',
                    data: this.data.map((item) => item.competitorValue),
                    borderColor: '#D9D9D9',
                },
            ]
        },

        chartData() {
            return {
                labels: this.chartLabels,
                datasets: this.groupByLabel === 'По брендам' ? this.chartDatasets : this.categoryChartDatasets,
            }
        },
    },

    methods: {
        changeSelectValue(value) {
            if (value === this.groupBy) return;
            this.$emit('groupBy', value);
            this.needCloseTooltip = !this.needCloseTooltip;
        },
    },
}
</script>

<style scoped lang="scss">
.card-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: color(gray-700);
}

.activator {
    padding-left: 12px;
    padding-right: 8px;
    display: flex;
    align-items: center;
    min-width: 140px;
    background-color: #fff;
    border: 1px solid #DFDFDF;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: #747474;
    outline: none;

    &:focus {
        outline: none;
    }
}

.icon {
    margin-left: auto;
}

.select-list {
    min-width: 140px;
    box-shadow: 0px 2px 4px 0px #00000024;
}

.value-button {
    padding: 16px 12px;
    width: 100%;
    background-color: #fff;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #212121;
    outline: none;

    &:hover {
        background-color: #DFDFDF;
    }

    &:focus {
        outline: none;
    }
}

.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
}

.chart-container {
    width: 100%;
    height: 100%;
}
</style>

<template lang="pug">
ul.charts-list(v-if="isLoading")
    li(v-for="number in 4" :key="number")
        ContainerForData(:titleVisible="false" width="100%" isLoading)

ul.charts-list(v-else)
    li(v-for="chart in selectedCharts" :key="chart.id")
        PriceAnalysisLineChart(
            v-if="chart.type === 'line'"
            :title="chart.title"
            :data="chart.data"
            :groupBy="chart.groupBy"
            :isLoading="chart.isLoading"
            :postfix="chart.postfix"
            @groupBy="changeChartGroupBy(chart, $event)"
        )

        PriceAnalysisBarChart(
            v-if="chart.type === 'bar'"
            :title="chart.title"
            :data="chart.data"
            :isLoading="chart.isLoading"
        )
                
</template>

<script>
import ContainerForData from '@/components/Nestle/ContainerForData.vue';
import PriceAnalysisLineChart from './PriceAnalysisLineChart.vue';
import PriceAnalysisBarChart from './PriceAnalysisBarChart.vue';

export default {
    components: {
        ContainerForData,
        PriceAnalysisLineChart,
        PriceAnalysisBarChart,
    },

    props: {
        requestParams: {
            type: Object,
            required: true,
        },
        needUpdate: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isLoading: false,
            chartsIds: [],
            chartsTypes: [
                {
                    id: 'DYNAMICS_OF_PRICES_PER_PACKAGE',
                    title: 'Динамика цен за упаковку',
                    type: 'line',
                    postfix: '₽',
                    groupBy: 'brand',
                    data: null,
                    isLoading: false,
                },
                {
                    id: 'DYNAMICS_OF_DISCOUNTS',
                    title: 'Динамика скидок',
                    type: 'line',
                    postfix: '%',
                    groupBy: 'brand',
                    data: null,
                    isLoading: false,
                },
                {
                    id: 'DYNAMICS_OF_PRICE_FOR_ALL_SKU',
                    title: 'Динамика цены для всех SKU',
                    type: 'bar',
                    data: null,
                    isLoading: false,
                },
                {
                    id: 'DYNAMICS_OF_PRICE_FOR_DISCOUNTED_SKU',
                    title: 'Динамика цен для товаров по скидке',
                    type: 'bar',
                    data: null,
                    isLoading: false,
                }
            ],
        }
    },

    computed: {
        selectedCharts() {
            return this.chartsTypes.filter((chart) => this.chartsIds.includes(chart.id));
        }
    },

    methods: {
        async changeChartGroupBy(chart, value) {
            chart.groupBy = value;
            await this.fetchChartData(chart);
        },

        async fetchCharts() {
            this.isLoading = true;

            try {
                const response = await this.$api.unilever.getPriceAnalysisCharts();
                const isResponseValid = response && Array.isArray(response.charts);
                this.chartsIds = isResponseValid ? response.charts : [];
            } catch {
                this.chartsIds = [];
            }

            this.isLoading = false;
        },

        async fetchAllChartsData() {
            const chartsRequests = this.selectedCharts.map((chart) => this.fetchChartData(chart));
            await Promise.all(chartsRequests);
        },

        async fetchChartData(chart) {
            chart.isLoading = true

            const params = {
                ...this.requestParams,
                chartId: chart.id,
            }

            if (chart.groupBy) {
                params.groupBy = chart.groupBy
            }

            try {
                const response = await this.$api.unilever.getChartData(params);
                const isDataValid = !!response && !!response.data;
                chart.data = isDataValid ? response.data : null;
            } catch {
                chart.data = null;
            }

            chart.isLoading = false;
        },
    },

    watch: {
        async needUpdate() {
            await this.fetchAllChartsData();
        }
    },

    async mounted() {
        await this.fetchCharts();
        await this.fetchAllChartsData();
    }
}
</script>

<style scoped lang="scss">
.charts-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
}
</style>

<template lang="pug">
.select-wrapper
    Tooltip(trigger="clickToToggle" :is-need-close-popup="needCloseTooltip" flat)
        template(slot="reference")
            slot(name="activator")
                button.filter-button
                    | {{ title }}
                    ChevronDownIcon.filter-icon(
                        :size="16"
                        :class="{'active': isOpened}"
                    )
        .content
            .search-wrapper(v-if="hasSearch")
                input.search-input(v-model="searchValue" :placeholder="searchPlaceholder")
                span.search-icon
                button.clear-search(v-if="searchValue" @click.stop="clearSearch")
                    span.clear-icon
                        CloseIcon

            .no-items(v-if="!items || !items.length")
                | Нет данных
            .no-items(v-else-if="searchValue && !currentItems.length")
                | Ничего не найдено. Попробуйте изменить параметры&nbsp;поиска.


            ul.items-list(v-else)
                li.item(v-for="item in currentItems" :key="item.id")
                    button.flex.items-center.select-button(@click="selectItem(item)")
                        span.mr-12 {{ item.name }}
                        CheckIcon.ml-auto.checked-icon(
                            v-if="selectedItem && selectedItem.id === item.id"
                            :size="16"
                        )
</template>

<script>
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue'
import CheckIcon from 'vue-material-design-icons/Check.vue'
import Tooltip from '@/components/Elements/Tooltip.vue';
import CloseIcon from '@/assets/svg/close.svg';

export default {
    components: {
        ChevronDownIcon,
        CheckIcon,
        Tooltip,
        CloseIcon,
    },

    props: {
        name: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        selectedItem: {
            type: Object,
            default: null,
        },
        hasSearch: {
            type: Boolean,
            default: false,
        },
        searchPlaceholder: {
            type: String,
            default: 'Поиск',
        }
    },
    data() {
        return {
            isOpened: false,
            needCloseTooltip: false,
            searchValue: '',
        }
    },
    computed: {
        title() {
            return this.selectedItem ? this.selectedItem.name : this.name;
        },
        currentItems() {
            const searchString = this.searchValue.toLowerCase();
            return this.items.filter((item) => item.name.toLowerCase().includes(searchString));
        },
    },
    methods: {
        selectItem(item) {
            if (this.selectedItem && this.selectedItem.id === item.id) return;
            this.needCloseTooltip = !this.needCloseTooltip;
            this.$emit('select', item);
        },
        clearSearch() {
            this.searchValue = '';
        },
    }
}
</script>

<style scoped lang="scss">
.filter-button {
    padding-left: 12px;
    padding-right: 8px;
    display: flex; 
    align-items: center;
    min-width: 118px;
    background-color: #fff;
    border: 1px solid #DFDFDF;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: #747474;
    outline: none;

    &:focus {
        outline: none;
    }
}

.filter-icon {
    margin-left: auto;
}

.items-list {
    max-height: 384px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #7A7A7A;
        border-radius: 7px;
    }
}

.search-wrapper {
    position: relative;
    padding: 10px 12px;
    width: 100%;
    height: 48px;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 12px;
        right: 12px;
        height: 1px;
        background-color: #E7E7E7;
    }
}

.select-button {
    padding: 15px 24px;
    padding-right: 12px;
    width: 100%;
    height: 100%;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #212121;

    &:hover {
        background-color: #F6F6FD;
    }

    &:focus {
        outline: none;
    }
}

.checked-icon {
    color: #524BCE;
}

.search-input {
    padding-left: 32px;
    padding-right: 32px;
    width: 100%;
    height: 100%;
    font-family: 'OpenSans';
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #000000;
    outline: none;

    &::placeholder {
        font-family: 'OpenSans';
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        color: #A3AEBE;
    }
}

.search-icon {
    position: absolute;
    display: block;
    left: 20px;
    top: 16px;
    width: 16px;
    height: 16px;
    background-image: url('~@/assets/img/search.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.clear-search {
    position: absolute;
    padding: 4px;
    right: 12px;
    top: 18px;
    width: 18px;
    height: 18px;
    outline: none;

    &:focus {
        outline: none;
    }
}

.clear-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    color: #9D9DB0;
    
    svg {
        width: 100%;
        height: 100%;
    }
}

.no-items {
    padding: 16px;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #212121;
}
</style>
